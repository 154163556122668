@import "variables";
@import '~@angular/material/theming';
@import "~bootstrap/scss/bootstrap-grid";

/* Theme */

@include mat-core();

$dercampus-theme: mat-light-theme($dc-primary, $dc-accent, $dc-warn);

@include angular-material-theme($dercampus-theme);


/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  hyphens: auto;
  background-image: url("../assets/images/background/light.svg");
  background-size: contain;
  background-attachment: fixed;
  animation:
    backgroundFadeIn 4s both 1s,
    backgroundPulse 4s 5s alternate infinite;
}

@keyframes backgroundFadeIn {
  from {
    background-color: rgba(mat-color($dc-primary), 0);
  }
  to {
    background-color: rgba(mat-color($dc-primary), 0.2);
  }
}

@keyframes backgroundPulse {
  from {
    background-color: rgba(mat-color($dc-primary), 0.2);
  }
  to {
    background-color: rgba(mat-color($dc-primary), 0.1);
  }
}

main, section {
  display: block;
}

a {
  color: mat-color($dc-primary);
  text-decoration: none;
  transition: color 300ms;

  &:hover {
    color: mat-color($dc-primary, darker);
  }
}

@mixin container-width-media-query($lower, $upper:'') {
  @if($upper) {
    @include media-breakpoint-between($lower, $upper) {
      $container-width: map_get($container-max-widths, $lower);
      --container-offset: calc(((100vw - #{$container-width} + #{$grid-gutter-width}) / 2));
    }
  }
  @else {
    @include media-breakpoint-up($lower) {
      $container-width: map_get($container-max-widths, $lower);
      --container-offset: calc(((100vw - #{$container-width} + #{$grid-gutter-width}) / 2));
    }
  }
}



:root {
  // Not working in production without this query
  @include media-breakpoint-between(xs, sm) {
    --container-offset: #{$grid-gutter-width / 2};
  }

  @include container-width-media-query(sm, md);
  @include container-width-media-query(md, lg);
  @include container-width-media-query(lg, xl);
  @include container-width-media-query(xl);
}

.container .full-width {
  width: calc(100% + 2 * var(--container-offset));
  margin-left: calc(-1 * var(--container-offset));
}

/** Angular Material Overwrite **/
// Multi-Line List |Lord forgive me
mat-list.multi-line {
  mat-list-item {
    height: auto!important;
    padding: 12px 0!important;
  }

  .mat-line {
    hyphens: auto;
    white-space: pre-wrap !important;
  }
}

// Stepper CDK Overwrite
.cdk-overlay-pane.no-padding > mat-dialog-container {
  padding: 0;
}

mat-horizontal-stepper.plain {
  > .mat-horizontal-content-container {
    padding-top: 24px;
  }
  > .mat-horizontal-stepper-header-container {
    display: none;
  }
}


/** Dialog **/

.cdk-overlay-pane.no-bg mat-dialog-container {
  background: none;
  box-shadow: none;
}

.cdk-overlay-backdrop {
  background-color: rgba(white, 0.9);
  // TODO Add frosted glass effect
}

.mat-bottom-sheet-container.full-screen {
  height: 100vh;
  max-height: none;
  max-width: none;
  padding: 0;
  background: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

